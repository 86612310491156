<template>
<!-- 统计症状管理 -->
    <div  class="communal system-account" ref="communal" >
        <!-- 顶部固定 -->
        <div class="top-fixed"  ref="topFixed">
          <div class="container fixed-style">
                <el-row >
                    <el-col :span="5" class="text-left">
                        <el-button type="success" style="margin-left: 0;" @click="addAlter('新增统计','')" >新增统计</el-button>
                    </el-col>
                    <el-col :span="19">
                        <el-input  placeholder="请输入关键字查询" v-model="content"></el-input>
                        <el-button type="success" plain @click="serach" >搜索</el-button>
                    </el-col>
                </el-row>
          </div>
          <div class="interval"></div>
        </div>
        <!-- 内容 -->
        <div class="content"  >
            <div class="container" :style="{height:contentHeight+'px'}">
                <div>
                    <template>
                            <el-table
                            :data="list"
                            style="width: 100%"
                            :height="contentHeight"
                            :row-class-name="tableRowClassName"
                            :empty-text="tableTip"
                            >
                            <el-table-column
                            prop="title"
                            label="症状名称"
                            >
                            </el-table-column>
                            <el-table-column
                            prop="username"
                            label="新增人"
                            >
                            </el-table-column>
                            <el-table-column
                            prop="addtime"
                            label="新增时间"
                            >
                            </el-table-column>
                            <el-table-column
                            prop="status"
                            label="订单"
                            >
                            </el-table-column>
                            <el-table-column
                             label="操作" fixed="right">
                            <template slot-scope="scope" >
                                <el-button type="text" @click="addAlter('统计信息',scope.row.id)">编辑</el-button>
                                <el-button type="text" @click.stop="operation(scope.row.id,'冻结')" v-if="scope.row.status=='正常'">冻结</el-button>
                                <el-button type="text" @click.stop="operation(scope.row.id,'解冻')" v-if="scope.row.status=='已冻结'">解冻</el-button>
                            </template>
                            </el-table-column>
                            </el-table>
                    </template>
                </div>
            </div>
        </div>
        <!-- 分页 100-->
        <div class="paging" ref="paging">
              <el-pagination
                            @current-change="handleCurrentChange"
                            @size-change="handleSizeChange"
                            :current-page="currentPage"
                            :page-sizes="[10,50,100,200]"
                            :page-size="psize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totals"
                ></el-pagination>
        </div>
        <!-- 新增统计 start -->
        <el-dialog
            :title="dialogTitle"
            :visible.sync="dialogVisible"
            width="480px"
            top="20%"
            >
            <div style="padding:30px 60px 0">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
                    <el-form-item label="症状名称" prop="symptomName">
                        <el-input v-model="ruleForm.symptomName" placeholder="请输入中心名称"></el-input>
                    </el-form-item>
                    <el-form-item label="">
                        <div class="cf5 font-s12" style="line-height:1.5">请谨慎填写，为防止统计出错，建议新增之后尽量不对其做口径较大的编辑</div>
                    </el-form-item>
                </el-form>
            </div>
            <!-- 按钮 -->
            <div class="account-footer clearfix">
                <div><el-button @click="dialogVisible = false">取 消</el-button></div>
                 <div><el-button type="primary"  @click="submitForm('ruleForm')">确 定</el-button></div>
            </div>
        </el-dialog>
        <!-- 新增统计 end -->
        <operationBtn  
          v-if="showHide"  
          :issue='issue' 
          :hintTxt='hintTxt' 
          :showHide="showHide" 
          :operationId='operationId'  
          :operationPsotUrl='operationPsotUrl' 
          :operationPsotName="operationPsotName"
          @childByOperationBtn="childByOperationBtn"
        ></operationBtn>
    </div>
</template>
<script>
import operationBtn from "../../components/operationBtn";
import { SymptomList } from "../../api/api"; //列表
import { StartSymptom } from "../../api/api"; //启用
import { AddSymptom } from "../../api/api"; //添加
import { SymptomInfo } from "../../api/api"; //详情
import { EditSymptom } from "../../api/api"; //修改
import { DelSymptom } from "../../api/api"; //冻结
export default {
  components: {
    operationBtn
  },
  data() {
    return {
      topHeight: "", //顶部搜索的高度
      mainHeight: "", //获取当前容器的高度
      pagingHeight: "", //获取分页的高度
      contentHeight: 0, //内容的高度
      totals: 0,
      pages: "1",
      psize: 10,
      currentPage: 1, //默认显示页
      list: [], //数据
      content: "", //搜索的内容
      dialogVisible: false,
      dialogTitle: "",
      ruleForm: {
        symptomName: "" //症状名称
      },
      rules: {
        symptomName: [
          { required: true, message: "请输入症状名称", trigger: "change" }
        ]
      },
      id: "", //保存点击行的账号id
      tableTip: "系统正在检索中…",
      // 操作性按钮弹框值   start
      issue: "", //问题   上面的文字
      hintTxt: "", //提示文本   下方的文字
      showHide: false, //弹框的隐藏显示
      operationId: "", //操作id
      operationPsotUrl: "", //操作接口名字
      operationPsotName: "" //请求参数名字
      // 操作性按钮弹框值   end
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    var that = this;
    that.common.Count.pageResize(that);
    window.onresize = function temp() {
      that.common.Count.pageResize(that);
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.pages = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.psize = val;
      this.getList();
    },
    serach() {
      //搜索
      this.getList("搜索");
    },
    getList(operationName) {
      var that = this;
      var db = {
        pages: this.pages,
        pnums: this.psize,
        name: this.content //搜索内容
      };
      this.$axios.post(SymptomList, db).then(
        res => {
          if (res.data.code == 1) {
            if (res.data.data != undefined && res.data.data.length > 0) {
              this.list = res.data.data;
              this.totals = res.data.count;
            } else {
              this.list = [];
              this.totals = res.data.count;
              if (operationName == undefined) {
                this.tableTip = "暂无症状记录";
              } else {
                this.tableTip = "暂无搜索结果";
              }
            }
          } else {
            this.list = [];
            this.tableTip = "暂无症状记录";
          }
        },
        err => {
          this.list = [];
          this.tableTip = "暂无症状记录";
        }
      );
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.status == "已冻结") {
        return "freeze";
      }
    },
    submitForm(formName) {
      //新建账号
      this.$refs[formName].validate(valid => {
        if (valid) {
          var postUrl;
          var db;
          if (this.dialogTitle == "统计信息") {
            //修改
            db = {
              title: this.ruleForm.symptomName,
              id: this.id
            };
            postUrl = EditSymptom;
          } else {
            //新增
            db = {
              title: this.ruleForm.symptomName
            };
            postUrl = AddSymptom;
          }
          this.$axios.post(postUrl, db).then(
            res => {
              if (res.data.code == 1) {
                this.getList();
                this.dialogVisible = false;
              } else {
                this.$message.error(res.data.msg);
              }
            },
            err => {
              this.$message.error(err.msg);
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    addAlter(btnName, id) {
      //查看,修改   新增统计
      this.dialogTitle = btnName;
      this.id = id;
      if (btnName == "统计信息") {
        //账号详情
        // this.ruleForm.symptomName
        var db = {
          id: id
        };
        this.$axios.post(SymptomInfo, db).then(
          res => {
            if (res.data.code == 1) {
              //中心详情
              this.ruleForm.symptomName = res.data.data.title; //中心名称
              this.dialogVisible = true;
            }
          },
          err => {
            this.$message.error(err.msg);
          }
        );
      } else {
        this.dialogVisible = true;
        this.clearForm();
      }
    },
    childByOperationBtn: function(showHide, dataCode) {
      //接收按钮式操作子组件传值
      this.showHide = showHide;
      if (dataCode == 1) {
        this.getList();
      }
    },
    operation(operationId, operationName) {
      //对数据的操作-按钮
      this.operationPsotName = "id";
      this.operationId = operationId; //修改组件id
      if (operationName == "冻结") {
        this.issue = "确定冻结该统计标签？";
        this.hintTxt = "冻结后系统将不再统计该病种数据";
        this.operationPsotUrl = DelSymptom;
      } else {
        this.issue = "确定解冻该统计标签？";
        this.hintTxt = "解冻后系统将继续统计该病种数据";
        this.operationPsotUrl = StartSymptom;
      }
      this.showHide = true; //显示组件
    },
    clearForm() {
      //重置表单
      if (this.$refs["ruleForm"] != undefined) {
        this.$refs["ruleForm"].resetFields();
      }
    }
  }
};
</script>

<style lang="scss">
.system-account {
  .login-hint {
    line-height: 40px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.25);
    padding-left: 6px;
    i {
      padding-right: 6px;
      font-size: 14px;
    }
  }
}
</style>
